/* !----------------------------- default design for video-vignette ----------------------------- */
.vignette {
	aspect-ratio: 16 / 9 !important;
}
.video-vignette-container {
	display: flex;
	gap: 15px;
	margin-bottom: 10px;
}
.video-vignette-container .iframe-video {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

iframe {
	width: 100%;
	border: none !important;
	aspect-ratio: 16/9 !important;
	clip-path: inset(1px 1px);
}
.video-vignette-container .iframe-video .aspec-ratio {
	height: 100%;
}
#iframe-video-edit,
#iframe-video-upload {
	width: 100%;
	height: 100%;
}
.wrapper {
	overflow-y: hidden;
	height: 100%;
}
.divider-thumbnail {
	height: 100%;
}
.paddingRadio {
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: visible;
	height: 100%;
}
.keywords {
	margin-top: 5px;
	display: flex;
	flex-wrap: wrap;
	height: 50%;
	width: 100%;
}
.keywords #video-input {
	flex: 1 1 100px;
	height: 32px;
}
.keywords .ant-tag {
	display: flex;
	align-items: center;
	height: 100%;
}
/* !----------------------------- media query for video-vignette ----------------------------- */
@media screen and (max-width: 991px) {
	.video-vignette-container {
		justify-content: center;
		gap: 15px;
		height: auto;
	}
	.wrapper {
		overflow-y: scroll;
	}
	.wrapper,
	.wrapper .ant-row,
	.video-vignette-container .iframe-video {
		height: 300px;
	}
	.divider-thumbnail {
		display: none;
	}
	.video-vignette-container .iframe-video iframe,
	#iframe-video-edit,
	#iframe-video-upload {
		width: 55vw !important;
		height: 29.9375vw !important;
	}
}
@media screen and (max-width: 767px) {
	.wrapper {
		overflow: hidden;
	}
	.wrapper,
	.wrapper .ant-row {
		height: fit-content;
	}
	.overlay {
		height: 100%;
		width: 100%;
	}
	.video-vignette-container .iframe-video iframe,
	#iframe-video-edit,
	#iframe-video-upload {
		width: 70vw !important;
		height: 37.375vw !important;
	}
}
@media screen and (max-width: 575px) {
	.video-vignette-container {
		gap: 8px;
	}
	.video-vignette-container .iframe-video,
	#iframe-video-edit,
	#iframe-video-upload {
		height: 45vw;
		width: 100%;
	}
}

@media screen and (min-width: 991px) {
	.paddingRadio {
		height: 70%;
	}
}
@media screen and (min-width: 1360px) {
	.paddingRadio {
		overflow: visible;
		height: 100%;
	}
}

/* !------------------------ design for upload file (edit/upload page) ----------------------- */
.choose-file-btn {
	background-color: #0969db !important;
	height: 40px;
	color: white;
	border-radius: 5px;
}

.uppy-DragDrop-note {
	white-space: pre-line;
	position: relative;
	top: 10px;
}

.uppy-DragDrop-inner svg {
	display: none;
}

.uppy-DragDrop-inner::before {
	content: url("/public/cloud-arrow-up-solid.svg");
	background-size: 100% 100%;
	display: inline-block;

	width: 70px;
	filter: opacity(0.2) drop-shadow(0 0 0 #adadad);
	position: relative;
	bottom: 10px;
}

.uppy-DragDrop-label {
	color: white !important;
	text-align: center;
	font-size: 16px;
	position: relative;
	/* bottom: 25px; */
	margin-left: auto;
	margin-right: auto;
	width: 20%;
	padding: 7px 4px;
	background-color: rgb(45, 50, 62);
	border-radius: 5px;
	font-family:
		Helvetica Neue,
		Helvetica,
		Arial,
		sans-serif !important;
}
.uppy-DragDrop-label:hover {
	background-color: rgb(45, 50, 62, 0.9);
}

.video_group {
	margin-bottom: 50px;
	max-height: 50px;
}

.rc-virtual-list-holder{
	overflow-y: auto !important;
	overflow-anchor: auto !important;
  } 
 .rc-virtual-list-scrollbar{
	visibility: hidden !important;
 } 
  
.tags-container {
	display: flex;
	max-width: 100%;
	flex-wrap: wrap;
	gap: 5px;
	margin-bottom: 12px;
}
.edit-tag {
	color: #222;
	font-size: 14px;
	padding: 5px 5px;
	gap: 5px;
}
