.loader-text {
  position: relative;
  color: rgba(0, 0, 0, 0.3);
  font-size: 2em;
}
.loader-text:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  color: #000;
  animation: loading 3s linear infinite;
}
@keyframes loading {
  0% {
    max-width: 0;
  }
}
