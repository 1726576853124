/* !---------------------- pagination --------------------------- */
.pagination-footer {
    width: 100%;
    height: 100%;
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-footer select {
    margin: 0 5px;
    height: 30px;
    width: auto !important;
}

.MuiPagination-ul li button svg {
    color: black;
}

.MuiPagination-ul li button {
    color: gray;
    background-color: transparent !important;
    margin: 0;
    padding: 0;
    min-width: 25px;
}

.MuiPagination-ul li .Mui-selected {
    color: black;
}

.pagination-right {
    display: flex;
    align-items: center;
    gap: 0 10px;
}

@media screen and (max-width:450px) {
    .pagination-text {
        display: none;
    }
}

/* homepage apercu modal */
.modal-podcast-apercu>.MuiBox-root {
    top: 30% !important;
    height: auto !important;
    background-color: transparent;
    box-shadow: none;
}

.modal-podcast-apercu .MuiTypography-root.MuiTypography-h6 {
    height: 0 !important;
}

.modal-podcast-apercu #modal-modal-title {
    padding: 0;
}

.themesPodcastGrid{
    background-color: rgba(0, 0, 0, 0.08);
    padding: 2px 6px;
    border-radius: 10px;
}

.themesPodcastAligns{
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
}
.themesPodcastAlignsmob{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.3rem;
}

.options-list-container {
    padding: 4px !important;
    border: 4px solid rgba(224, 224, 224, 1) !important;
    border-bottom: none !important;
  }