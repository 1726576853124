@media screen and (min-width:1700px) {
    .MuiDataGrid-virtualScroller{
      overflow-x: hidden !important;
    }
  }
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
    display: block;
    height: 6px !important;
}
  @media screen and (max-width:420px) {
    .MuiDataGrid-virtualScroller{
      overflow-x: scroll !important;
    }
  } 
