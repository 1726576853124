/* !-------------- RSS file link modal ---------------- */

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif 1.4rem !important;
  }
  
  .modal_first_row,
  .modal_second_row,
  .embed_code {
    display: grid;
    grid-template-columns: 20% 75% 5%;
    padding: 1em;
    gap: 0 5px;
    align-items: center;
  }
  
  .textAreaTemplate {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.85);
    padding: 0.5em;
    overflow: hidden;
    resize: none;
    height: 150px;
  }
  
.categoriesPodcast{
  padding-bottom: 25px;
}
.sous-header{
  height: calc(100vh - 110px);
}
.data-table{
  height: calc(100% - 90px);
}
  @media screen and (max-width:600px) {
  
    .modal_first_row,
    .modal_second_row,
    .embed_code {
      grid-template-rows: auto auto;
      grid-template-columns: 90% 5%;
      gap: 10px 5px;
    }
  
    .modal_first_row :nth-child(2),
    .modal_second_row :nth-child(2),
    .embed_code :nth-child(2) {
      grid-column: 1/2;
      grid-row: 2/3;
    }
  }
  
  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    white-space: nowrap !important;
  }
  
  .css-hlj6pa-MuiDialogActions-root {
    margin-right: 12px !important;
    padding-right: 12px !important;
  }
  
  .modal-confirm-button {
    width: unset !important;
    height: unset !important;
  }
  
  .containerPodcast .css-xsqzfj-MuiPaper-root {
    opacity: 0.42 !important;
    height: 40px !important;
    box-shadow: 0px 4px 10px 0px rgba(0 ,0 ,0 ,0.8);

  }
  
  .containerPodcast .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 7.5px 15px 0 !important;
  }
  
  .containerPodcast .searchBar {
    padding-top: 6px !important;
  }
  
  .containerPodcast .header_container_div {
    margin: 10px 0px !important;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
    box-shadow: 0px 4px 5px 2px rgba(0 ,0 ,0 ,0.1);

  }
  .css-ypiqx9-MuiDialogContent-root {
    margin-top: 25px;
  }
  .header_title{
    display: flex !important;
    gap: 0.5em;
    white-space: nowrap;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  }
  .header_container_div_main_page {
    margin-bottom: 6px !important;
    height: 66px !important;
  }
  
  .edit_podcast_container label {
    color: #000 !important;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
    font-weight: 500 !important;
    margin-bottom: 5px !important;
  }

  .contenu_container_Edit .css-ahj2mt-MuiTypography-root{
    font-family: unset !important;
    margin-top: unset !important;
    font-weight: unset !important;
    font-size: unset !important;
    line-height: unset !important;
  }

  .shadowShouldBeADDED{
      box-shadow: 0px 4px 10px 2px rgba(0 ,0 ,0 ,0.12);
      border-radius: 4px;
  }

  .widthFullForPodcastSearch{
    width: 100%;
  }

  .shadowShouldBeADDED .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  fieldset {
    border-color: rgba(224, 224, 224, 1) !important;
  }
  .Podcast-details-modal{
    width: 100%;
    display: flex;
    flex-direction: column;
    .Podcast-details-modal-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      padding-bottom: 1%;
      .Podcast-details-modal-title{
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      }
      .Podcast-details-modal-value{
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        height: auto;
        padding: 11px;
        border: 1px solid #e0e0e0;
        color: RGBA(0, 0, 0, 0.85);
      }
    }
  }