*,*::after,*::before {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
/* scrollbar design */
::-webkit-scrollbar {
	width: 0.6em;
}
::-webkit-scrollbar-track {
	background-color: rgb(196, 196, 196);
	border-radius: 50vw;
	margin-block: 0.4em;
}
::-webkit-scrollbar-thumb {
	background-color: rgb(132, 135, 138);
	border-radius: 50vw;
	border: 0.1em solid rgb(196, 196, 196);
}
::-webkit-scrollbar-thumb:hover {
	background-color: rgb(76, 79, 82);
	width: 0.6em;
}
@supports (scrollbar-color: red blue) {
	* {
		scrollbar-color: rgb(132, 135, 138) rgb(196, 196, 196);
		scrollbar-width: auto;
	}
}

.center {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.iframeVideo {
	overflow: hidden;
}

.iframe-video {
	width: 100%;
	height: 99vh;
}

.custom-modal-metadata .ant-modal-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 15px
}

@media only screen and (min-width: 768px) {
	.iframeVideo {
		width: 100%;
		height: calc(99vh - 180px);
		min-height: 600px;
	}
}
