.change-video-source{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* video style */
.change-video-source .video-source iframe{
    width: 40vw;
    height: 30vh;
}
/* single thumbnail style */
.change-video-source .single-vignette{
    min-width: 200px;
    min-height: 180px;
    width: 25%;
    height: 20vh;
}
.change-video-source .single-vignette *{
    width: 100%;
    height: 100%;
}
.change-video-source .hr-source{
    width: 70vw;
    height: 10px;
    border: 0;
    box-shadow: 0 10px 10px -10px #8c8c8c inset;
}
/* thumbnails style */
.change-video-source .div-vignette{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:10px;
}
.change-video-source .div-vignette .ant-col{
    width: 200px;
}
.change-video-source .div-vignette .ant-col .overlay,
.change-video-source .div-vignette .ant-col .container{
    width: 200px;
    height: 180px;
}
.change-video-source .overlay .text,
.change-video-source .container img{
    width: 100%;
    height: 100%;
}
/* buttons style */
.change-video-source .buttons{
    /* align-self: flex-end; */
    margin:20px 0;
    display: flex;
    gap: 0 20px;
}
/* responsive <1000px */
@media screen and (max-width:1000px) {
    /* video style */
    .change-video-source .video-source iframe{
        width: 80vw;
        height: 40vh;
    }
    /* single thumbnail style */
    .change-video-source .single-vignette{
        width: 50%;
        height: 20vh;
    }
}
/* responsive <576px */
@media screen and (max-width:576px) {
    /* video style */
    .change-video-source .video-source iframe{
        width: 80vw;
        height: 30vh;
    }
}