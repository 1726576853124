.container-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
  .video-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
    .video-404 svg{
    width: 50% !important;
    height: 70% !important;
  }
  .video-404 h1 {
    margin: 5px 0;
    font-size: 20px;
    text-align: center;
    color: #3d4964;
  }
  .video-404 p{
    font-size: 12px;
    text-align: center;
    color: #3d4964;
  }
  .container-404 h1 {
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    color: #284997;
  }
  .container-404 p {
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    color: #284997;
  }
  
  .container-404 img {
    max-width: 100%;
  }