@import "~antd/dist/antd.css";

@font-face {
  src: url(assets/fonts/PingFangSCRegular.ttf);
  font-family: PingFangSC;
}

*,*::after,*::before {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
html,body{
  height:auto;
}
body {
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

/* HEADERMEDIA */
.HeaderMedia {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

/* Icons.jsx */
.modal_header {
  display: flex;
  justify-content: space-between;
  margin: 6px
}


.Apercu_modal_header .cancelX{
  cursor: pointer;
  position: absolute;
  right: 5px;
}

.details_section {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 10px 18px
}
.details_section p:nth-child(2){
  text-align:end;
}
.details_section p{
  text-align:end;
  max-height: 50px;
}
.details_section p:nth-child(2){
  max-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.details-Modal {
  display: flex;
  justify-content: space-between;
  margin: 6px
}

d {
  cursor: pointer;
}


.line {
  border-bottom: 1px solid #e8e8e8;
  width: 98%;
   margin-left: 10px;
}

/* --- * --- */
.TousMedia {
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: RGBA(0, 0, 0, 0.04);
  margin-top: 0.5%;
  padding: 0 2em;
}

.TousMedia h4 {
  font-size: 20px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
}

.btn_add_media {
  font-size: 14px;
}

.Filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.7%;
}

.rect_grid {
  width: 12%;
  height: 40px;
  border-radius: 2px;
  border: 1px solid RGBA(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2%;
}

.icon_grid1 {
  font-size: 13.5px;
  cursor: pointer;
  border-right: 1px solid RGBA(0, 0, 0, 0.06);
  width: 33%;
  color: RGBA(0, 0, 0, 0.65);
}

.icon_grid2 {
  cursor: pointer;
}

.icon_grid3 {
  font-size: 13.5px;
  cursor: pointer;
  width: 33%;
  color: RGBA(0, 0, 0, 0.65);
  border-left: 1px solid RGBA(0, 0, 0, 0.06);
}

#active {
  color: RGB(24, 144, 255);
}

.rect_colonne {
  width: 20%;
  height: 40px !important;
}

.selectFilter {
  width: 100%;
  height: 40px !important;
  font-size: 16px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.65);
}


.rect_filter {
  width: 65%;
  height: 40px;
}

.inputFilter {
  height: 100%;
}

.filter_icon {
  width: 40px !important;
  height: 40px !important;
  padding: 5px;
  margin-right: -11px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
  border-left: 1px solid #d9d9d9 !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.class_icon_filter {
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.filter_icon:hover .class_icon_filter {
  color: #40a9ff;
}

#activeIcon {
  color: #40a9ff;
}

.div_Filter_global {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: black;
  position: relative;
}

.div_Filter {
  width: 65%;
  margin-top: 0.5%;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  background-color: RGB(255, 255, 255);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  z-index: 9;
  padding: 1%;
}

.div1_div_Filter {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.select_div1_div_Filter {
  height: 32px !important;
  width: 49%;
}

.input_div1_div_Filter {
  width: 49%;
}

.div2_div_Filter {
  margin-top: 1%;
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.div_filter_avance {
  margin-top: 1%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#lg-share-googleplus {
  display: none !important;
}

.div1_div_filter_avance {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.icon-filter-avance {
  font-size: 8.88px;
  margin-right: 1%;
  color: RGBA(0, 0, 0, 0.65);
}

.span-filter-avance {
  width: 50%;
  font-size: 14px;
  color: RGBA(0, 0, 0, 0.65);
}

.div_filter_avance_global {
  width: 100%;
  padding: 0.5% 0;
  margin-top: 0.5%;
  display: flex;
  flex-direction: column;
}

.div_checkbox {
  width: 100%;
}

.chbx {
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.65);
}

.div_date_picker {
  width: 100%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.div_date_picker .ant-select-selector {
  height: 32px !important;
}

.slct {
  width: 10%;
  margin-right: 1%;
}

.DatePicker_filter_avance {
  width: 50%;
  margin-right: 1%;
}

.input_filter_avance {
  width: 30%;
  margin-right: 1%;
}

.div_OU {
  margin-top: 1%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.div_OU .ant-select-selector {
  height: 32px !important;
}

.slct_1_div_OU {
  width: 7.2%;
  margin-right: 1%;
}

.slct_2_div_OU {
  width: 36.5%;
}

.div_button_filter {
  width: 100%;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.btn_1 {
  margin-right: 1%;
}

/* HEADERMEDIA */

/* Meta Data */
.MetaData {
  width: 100%;
  margin-top: 1.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.div_folder {
  width: 33%;
  height: 700px;
  border-right: 1px solid RGB(217, 217, 217);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div_photos {
  width: 33%;
  height: 700px;
  border-right: 1px solid RGB(217, 217, 217);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.div_card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.div1_div_folder {
  width: 95%;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1% 5%;
}

#id_div1_div_folder {
  background-color: RGB(230, 247, 255);
}

.title_folder {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.folder_icon_antd {
  font-size: 17.5px !important;
  color: RGBA(0, 0, 0, 0.45);
  margin-right: 4%;
}

.description_folder {
  font-size: 14px;
  color: RGBA(0, 0, 0, 0.85);
}

.arrow_folder {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2%;
}

.arrow_icon_antd {
  font-size: 17px;
  color: RGBA(0, 0, 0, 0.25);
}

.ellipsis_icon {
  font-size: 19px;
}

.menu {
  width: 162px !important;
}

.div1_div_photos {
  width: 90%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 2%;
}

.div1_div_photos img {
  width: 93px;
  height: 54px;
  margin-right: 4%;
}

.div1_div_photos span {
  font-size: 14px;
  color: RGBA(0, 0, 0, 0.85);
}

.div1_div_card {
  width: 90% !important;
  height: 300px !important;
  margin: 0 auto;
  background-repeat: no-repeat !important;
  background-position: 10%;
  background-size: cover;
  position: relative;
}

.div1_div_card img {
  width: 100% !important;
  height: 100% !important;
}

.btn_div_card {
  position: absolute;
  top: 5px;
  right: 8px;
  background-color: #40a9ff !important;
  border: 1px solid #40a9ff;
  color: white;
}

.div_title {
  width: 90% !important;
  margin: 0 auto;
}

.title_image {
  font-size: 16px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
  margin: 2% auto;
}

.div_propriete {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}

.div_propriete_gauche,
.div_propriete_droite {
  width: 50%;
}

.div1_div_propriete_gauche,
.div1_div_propriete_droite {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8%;
}

.div2_div_propriete_gauche,
.div2_div_propriete_droite {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8%;
}

.div3_div_propriete_gauche,
.div3_div_propriete_droite {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8%;
}

.span1_proprité {
  font-size: 12px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.45);
  margin-bottom: 3%;
}

.span2_proprité {
  font-size: 14px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
}

.div_folder_clicked {
  width: 90%;
  margin: 0 auto;
}

.span1_div_folder_clicked {
  margin-bottom: 4%;
}

.div1_div_folder_clicked {
  margin-top: 2%;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.drp_prop {
  height: 32px;
  width: 32px;
  border-radius: 2px;
  border: 1px solid RGBA(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lg-img-wrap {
  background-color: RGBA(214, 214, 214, 0.8) !important;
}

.lg-backdrop {
  background-color: RGBA(214, 214, 214, 0.8) !important;
}

.lg-thumb-outer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.lg-thumb {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.lg-thumb-item {
  margin-right: 2% !important;
  cursor: pointer !important;
}

.lg-thumb-item:hover {
  border: 2px solid RGB(0, 126, 252) !important;
}

.lg-prev,
.lg-next {
  background-color: RGB(255, 255, 255) !important;
  border: 1px solid RGBA(0, 0, 0, 0.15) !important;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: RGB(255, 255, 255) !important;
  border: 1px solid RGBA(0, 0, 0, 0.15) !important;
}

.active {
  border: 2px solid RGB(0, 126, 252) !important;
}

.lg-download {
  display: none !important;
}

#lg-actual-size {
  display: none !important;
}

.lg-toolbar {
  background-color: #0d0a0a !important;
}

/* Meta Data */

/* Data Table*/
.DataTable {
  margin-top: 1%;
}

/* Data Table */

/*MediaCard*/
.MediaCard {
  margin-top: 1.5%;
}

.DeleteColumn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}

.DeleteColumn Button {
  margin-right: 0.5%;
  width: 40px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DeleteColumn span {
  font-size: 14px;
  color: RGBA(0, 0, 0, 0.85);
}

.BodyCard {
  width: 100% !important;
  height: 730px !important;
  overflow-y: scroll !important;
  margin-top: 1.5% !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.BodyCard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.BodyCard::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.BodyCard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: black;
}

.card {
  width: 19%;
  height: 300px;
  margin-right: 1%;
  margin-bottom: 2%;
  border-radius: 2px;
  border: 1px solid RGBA(0, 0, 0, 0.06);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.card:hover {
  background-color: #e6f7ff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card img {
  width: 100% !important;
  height: 70% !important;
}

.container_card {
  padding-bottom: 2%;
  padding-left: 6%;
  padding-right: 6%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.h4_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 2%;
  font-size: 14px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
}

.type_title {
  margin-left: 4%;
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.45);
}

.icon_type {
  color: RGBA(0, 0, 0, 0.45);
}

.type_div {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.type_div div {
  height: 100% !important;
  width: 50% !important;
}

.div_type_file {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
}

.div_drp_file {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.chkbx {
  position: absolute;
  top: 132px;
  left: 10px;
}

/*MediaCard*/

/*ExportMedia*/

.div-media {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.arrow-left {
  font-size: 20px;
  font-weight: 500;
  margin-right: 0.5%;
  color: RGBA(0, 0, 0, 0.85);
}

.span-medias {
  font-size: 20px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
}

.div_exportMedia {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

.div_vignette {
  width: auto;
  height: 194px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_div_vignette {
  width: auto;
  height: 100% !important;
}

.span_div_vignette {
  font-size: 12px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.85);
}

.ExporterLien {
  margin-top: 1.5%;
  width: 45%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid RGB(217, 217, 217);
}

.span_ExporterLien {
  font-size: 16px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
}

.reseauxSociaux {
  margin-top: 1%;
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.span_reseauxSociaux {
  margin-right: 4%;
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.65);
}

.groupRadio {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.radioButton {
  font-size: 36px;
  color: RGBA(0, 0, 0, 0.25);
  margin-right: 2%;
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Permalien,
.CodeEmbed {
  width: 45%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.span_Permalien {
  margin-right: 8.5%;
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.65);
}

.inputPermelien {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.input_permalien {
  margin-right: 1%;
}

.btn_permalien,
.btn_CodeEmbed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid RGBA(0, 0, 0, 0.15);
  color: RGBA(0, 0, 0, 0.45);
}

.span_CodeEmbed {
  margin-right: 7%;
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.65);
}

.inputCodeEmbed {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.textAreaCodeEmbed {
  min-height: 114px !important;
  margin-right: 1%;
}

.div_Partage {
  width: 45%;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sharePartage {
  width: 5%;
  font-size: 25px;
  margin-right: 2%;
  color: RGBA(0, 0, 0, 0.65);
}

.NumeroPartage {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.span1NumeroPartage,
.span2NumeroPartage {
  margin-right: 4%;
  font-size: 16px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.65);
}

.NamePartage {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.NamePartage Button {
  width: 90px;
  height: 31.86px;
  color: RGBA(0, 0, 0, 0.25);
  border: 1px solid RGBA(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 15.75px;
}

.NamePartage .btnFb:hover {
  background-color: #3b5998 !important;
  border: 1px solid #3b5998 !important;
  color: white !important;
}

#activeBtnFB {
  background-color: #3b5998 !important;
  border: 1px solid #3b5998 !important;
  color: white !important;
}

.NamePartage .btnIn:hover {
  background-color: #0e76a8 !important;
  border: 1px solid #0e76a8 !important;
  color: white !important;
}

#activeBtnIN {
  background-color: #0e76a8 !important;
  border: 1px solid #0e76a8 !important;
  color: white !important;
}

.NamePartage .btnTw:hover {
  background-color: #00acee !important;
  border: 1px solid #00acee !important;
  color: white !important;
}

#activeBtnTW {
  background-color: #00acee !important;
  border: 1px solid #00acee !important;
  color: white !important;
}

.NamePartage .btnIns:hover {
  background-color: #dd2a7b !important;
  border: 1px solid #dd2a7b !important;
  color: white !important;
}

#activeBtnINS {
  background-color: #dd2a7b !important;
  border: 1px solid #dd2a7b !important;
  color: white !important;
}

.NamePartage .btnEm:hover {
  background-color: #ff7f00 !important;
  border: 1px solid #ff7f00 !important;
  color: white !important;
}

#activeBtnEM {
  background-color: #ff7f00 !important;
  border: 1px solid #ff7f00 !important;
  color: white !important;
}

.div_action {
  display: flex;
}

/*ExportMedia*/

.pdf_media {
  width: 93px;
  height: 54px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: RGB(243, 243, 243);
}

.apercu_image {
  width: 93px;
  height: 54px;
  border-radius: 2px;
}

.video_aperçu {
  position: relative;
  width: 93px;
  height: 54px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.video_thumb {
  position: absolute;
  width: 93px;
  height: 54px;
}

.play_video_icon {
  position: absolute;
  font-size: 26px;
  top: 14px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #cecdd8;
}

/* hatem custom css */

.mb-16 {
  margin-bottom: 16px;
}

/* media card item */
.media_card_item {
  width: 100%;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 2px;
  margin-top: 24px;
}

.media_card_item_body {
  height: 119px;
  background: #f0f0f0;
  position: relative;
  width: 100%;
}

.card_item_icon {
  color: rgb(186, 186, 186);
  position: absolute;
  left: calc(50% - 25px);
  font-size: 50px;
  top: calc(50% - 25px);
}

.media_card_item_image {
  position: absolute;
  height: 100%;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  width: 100%;
}

.media_card_video_play_icon {
  color: rgb(213, 213, 213);
  font-size: 40px;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
}

.card_media_item_chekcbox {
  position: absolute;
  bottom: 0;
  margin-left: 8px;
  margin-bottom: 8px;
}

.media_card_item_footer {
  height: 65px;
  padding: 8px;
}

.card_media_item_title {
  color: RGBA(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 1.571;
  font-size: 14px;
}

.size_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.size_actions .size {
  color: RGBA(0, 0, 0, 0.45);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.571;
  margin-top: 4px;
}

.footer_icon {
  font-size: 16px;
  color: rgba(112, 112, 112, 0.45);
  margin-right: 6px;
}

.size_content {
  display: flex;
  align-items: center;
}

.card_media_item_options {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}

/* page scrool bar  */

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: RGB(212, 212, 212);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ---------------------------
  page header
  ------------------------- */
.media_page_header {
  background: rgb(241, 242, 245);
  height: 58px;
  margin-top: 16px;
}

/*-----------------------------
 info media 
 -----------------------------*/

.infosMedias {
  width: 100%;
  padding: 24px;
}

.media_page_header {
  padding: 8px 24px !important;
}

.page_header_button {
  height: 32px !important;
  width: 124px !important;
}

.media_data {
  margin-top: 40px;
}

.media_info_image {
  width: 100%;
}

.info_section {
  margin-top: 24px;
}

.info_section_title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}

.separator {
  height: 1px;
  background-color: rgb(217, 217, 217);
  margin-top: 8px;
}

.info_row {
  margin-top: 16px;
}

.info_title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;
}

.info_value {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
}

.exif_collapse {
  margin-top: 24px;
}

@media only screen and (min-width: 600px) {
  .media_info_image {
    height: 432px;
  }
}

/*-----------------------------
 export media 
 -----------------------------*/

.export_media {
  padding: 16px;
}

.media_file {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.export_file {
  width: 346px;
  height: 194px;
}

.image_name {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.667;
}

.export_link {
  display: table;
  margin: 0 auto;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.social_icon {
  border: 1px solid lightgrey;
  border-radius: 2px;
  height: 25px;
  width: 25px;
}

.social_network_icon {
  font-size: 20px;
  color: rgb(158, 158, 158);
  margin: 2px;
}

.export_label {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.share_section {
  display: flex;
  align-items: center;
}

.share_icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
}

.share_text {
  color: RGBA(0, 0, 0, 0.65);
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
  margin-left: 18px;
}

.share_button {
  border: 1px solid RGBA(0, 0, 0, 0.15);
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  cursor: pointer;
  height: 31px;
  margin-left: 19px;
}

.share_button_icon {
  color: rgb(99, 98, 98);

  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .media_file {
    margin-top: 30px;
  }

  .export_file {
    width: 100%;
  }
}

/* ----------------------------------------
  edit media page
  ---------------------------------------- */

.edit_media {
  padding: 16px;
}

.edit_media_body {
  margin-top: 32px;
}

.edit_media_image {
  position: relative;
}

.edit_media_image img {
  height: 230px;
  width: 100%;
  margin-bottom: 16px;
}

.form_item {
  margin-bottom: 16px;
}

.form_item_title {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.85);
}

.linked_files {
  height: 48px;
  background: RGBA(0, 0, 0, 0.04);
  border: 1px solid RGBA(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.liked_files_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.85);
}

.no_linked_files_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: RGBA(0, 0, 0, 0.45);
}

.linked_file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid RGBA(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
}

.left_content img {
  width: 75px;
  height: 50px;
  margin-right: 8px;
}

.right_content {
  width: 67px;
  display: flex;
  justify-content: space-around;
}

/* ---------------------------
  add media
  --------------------------- */

.add_media {
  padding: 16px;
}

/* -------------------------
medai source component 
----------------------------*/

.sources {
  width: 100%;
  margin-top: 32px;
  border: 1px solid RGBA(0, 0, 0, 0.15);
  border-style: dashed;
  border-radius: 4px;
  background: RGBA(0, 0, 0, 0.04);
}

.sources_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.two_town_icon {
  font-size: 40px;
}

.sources_top span {
  color: RGBA(0, 0, 0, 0.85);
  line-height: 1.5;
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
  font-weight: 400;
}

.item_wrapper {
  padding: 8px;
}

.source_item {
  border: 1px solid RGBA(0, 0, 0, 0.15);
  background: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 19px 0px;
}

.source_item img {
  height: 26px;
  width: 26px;
}

.source_item span {
  color: RGBA(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 4px;
}

.source_elements {
  padding: 8px 8px 73px 8px;
}

@media only screen and (max-width: 600px) {
  .item_wrapper {
    width: 33%;
    padding: 5px 5px;
  }
}

@media only screen and (min-width: 600px) {
  .item_wrapper {
    width: 20%;
  }
}

/* -----------------------------------
link source
------------------------------------ */

.link_source {
  width: 100%;
  background: RGBA(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 1px solid RGBA(0, 0, 0, 0.15);
  margin-top: 32px;
  padding: 0px 24px;
}

.link_source_header {
  position: relative;

  width: 100%;
  padding: 9px 0px;
  border-bottom: 1px solid RGB(217, 217, 217);
}

.goback {
  color: RGB(24, 144, 255);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  line-height: 1.5;
}

.link_source_header_title {
  position: absolute;
  left: 45%;
}

.link_source_form {
  display: flex;
  margin-top: 102px;
}

.link_source_form img {
  margin-right: 18px;
}

.link_source_buttons {
  display: flex;
  justify-content: center;
  margin-top: 48px;
  padding-bottom: 81px;
}

.link_source_buttons button {
  margin: 0px 8px;
}

/* ------------------------------
google drive source
-------------------------------- */

.from_google_drive {
  display: flex;
  justify-content: center;
  margin-top: 52px;
}

.from_google_drive {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.from_google_drive img {
  width: 28px;
  height: 24px;
}

.from_google_drive_text {
  color: RGBA(0, 0, 0, 0.85);
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 48px;
  margin-top: 20px;
  max-width: 45ch;
}

.imported_data_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 20px;
  padding: 13px 20px;
  border-bottom: 1px solid RGB(217, 217, 217);
}

.imported_data_left_header {
  display: flex;
  align-items: center;
}

.imported_data_left_header img {
  height: 15px;
  width: 18px;
}

.imported_data_left_header span {
  font-size: 14px;
  line-height: 1.5;
  color: RGBA(0, 0, 0, 0.85);
  font-weight: 400;
  margin-left: 10px;
}

.imported_data_right_header {
  display: flex;
}

.disconnect {
  margin-left: 48px;
}

.disconnect span {
  color: RGBA(0, 0, 0, 0.45);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.imported_data_email {
  font-size: 14px;
  line-height: 1.5;
  color: RGBA(0, 0, 0, 0.85);
}

@media only screen and (max-width: 600px) {
  .imported_data_right_header {
    display: flex;
    flex-direction: column;
  }

  .imported_data_header {
    padding: 13px 0px;
  }

  .disconnect {
    margin-top: 10px;
  }
}

/* Podcast.jsx */

.containerPodcast {
  margin: 10px;
}

.onDelete {
   display: flex;
  align-items: center;
  justify-content: space-between; 
}

.breadCumb {
  padding: 15px;
}

/*  */
.searchBarOnDelete {
  padding-bottom: 15px;
  width: 97%;
  display: flex;
  gap:10px !important;
  padding-bottom: 1.5em !important;
  padding-top:  1em !important;

}


.searchBar {
  padding-bottom: 15px;
  display: flex;
  gap:25px !important;
  padding-top: 1em !important;
  padding-bottom: 1.5 !important;
  
}
@media only screen and (max-width: 500px) {
  .searchBar {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap:10px !important;
    padding-top: 1em !important;
    padding-bottom: 1.5 !important;
    
  }
}

.searchComponent {
  background-color: #f8f8ff;
  width: 100%;
}



/* ---- * ---- */

/* Table.jsx */

.data-grid {
  height: 100%;
  width: 100%;
}

.data-grid-mobile {
  height: 100%;
  width: 100%;
}

.data-grid-tablette {
  height: 100%;
  width: 100%;
}

.icons_cont {
  display: flex;
  align-content: center;
  justify-content: center;
}
.icons_cont>div:hover{
  cursor: pointer;
}


.apercu_image {
  width: 50px;
  height: 50px;
}

/* ---- * ----  */

/* PageHeader.jsx */

.pageheader-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
}

.content1 {
  padding: 5px;

}

/*  podcast list page*/
#checkbox-dropdown{
  display: none;
}
@media screen and (max-width:600px) {
  .MuiList-root.MuiMenu-list .icons_cont{
    width: 200px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

/* edit podcast module */

.edit_podcast_container {
  margin: 25px;
  height: 110vh;
}

@media screen and (max-width : 992px) {
  .button_container {
    gap: 10px;
  }
}

.podcast_image {
  width: 180px;
  height: 101px;
  margin-left: 15px;
}

.edit_vignette {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.upload_button {
  background-color: rgb(45, 50, 62);
  border: none;
  color: white;
  display: flex;
  align-content: center;
  padding: 10px;
  border-radius: 6.5px;
  height: 45px;

}

.uploadCard {
  background-color: #039BE5;
  border: none;
  color: white;
  display: flex;
  align-content: center;
  padding: 10px;

}

.tg_cntr {
  width: fit-content;
}



@media screen and (max-width : 992px) {
  .upload_button {
    padding: 1em;
    justify-content: center;
    align-items: center;
  }

  .tg_cntr {
    width: fit-content;
  }

}

.file_upload_container {
  padding-top: 20px;
}


.maximum_upload_size {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: rgb(210, 210, 210);
  display: block;
  width: 280px;
  margin-top: 2rem;
}
.date-pickers {
  display: flex;
  flex-direction: row;
  gap:20px;
  width: 100% !important;
  justify-content: space-between;
}

.button_container {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  padding: 1.5em 0 1.5em 0 !important;
}


/* edit podcast */

.contenu_container {
  display: flex;
  align-content: center;
  gap: 25px;
  padding: 1em;
  flex-direction: row;
}

.checkboxes {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type=checkbox] {
  transform: scale(1.5);
}

.checkboxes label {
  display: inline-block;
  padding-right: 10px;
  white-space: nowrap;

}

.checkboxes input {
  vertical-align: middle;

}

.checkboxes label span {
  vertical-align: middle;

}

.span {
  font-size: 140%;
}

input[type=radio] {
  border: 0px;
  height: 1.6em;
}


.radio_btn_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contenu_container_Edit {}

.radioButtons {
  display: flex;
  flex-direction: column;
}

.radio_btn_container_edit {
  display: flex;
  gap: 10px;
  margin-top: 1em;
}



.toggle_btn_container {
      width: 897px !important ;
      background-color: #eee;
      color: #444;
      cursor: pointer;
      padding: 12px;
      width: 100%;
      border: none;
      text-align: left;
      outline: 0;
      font-size: 15px;
      transition: .4s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 58px !important;
}
.css-if1fzi-MuiButtonBase-root-MuiButton-root {
  padding: 0 !important;
}

.toggle_image {
  width: 149px;
  height: 101px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.select_upload {
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  margin-bottom: 10px;
}

.upload_pod_container {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.upload_card {
  background-color: white;
  min-width: 70%;
  min-height: 27%;
  border: 1px solid rgb(218, 215, 215);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 9px;
  margin-top: 2%;


}


.option_paragraph {
  color: rgb(146, 143, 143);
  text-align: center;

}

.fileUploaded {
  display: flex;
  flex-direction: column;
  align-items: flex-start
}

@media screen and (max-width : 992px) {

  .upload_card {
    height: 70% !important;

  }

  .intro {
    font-size: medium;
  }

  .toggle_btn_container {
    width: 100% !important; 
    display: flex !important ;
    height: fit-content !important;
    width: 339px !important;
    margin-bottom: 1em;
  }

  .button_container {
    width: 100%;
    justify-content: flex-start
  }
  .upload_file_inputs_toggle {
    width: 100%;
   margin-top: 1.5em;
  }
  .upload_file_inputs_add {
    width: 100%;
    margin-top: 1.5em;

  
  }
  .tglHeader {
    width: 100%;
  }
  .tg_cntr {
    width: 100%;
  }
  .tgl_container {
    width: 100%;
  }
}




/* Add podcast main  */


.intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px
}

.align-progress-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inside_ {
  display: flex;
  flex-direction: column;
  align-items: center;
}














.header {
  width: 80%;
}

.icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1em
}

.uppy-DragDrop-container {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important
}

.uppy-DragDrop--isDragDropSupported {
  border: 1px solid #f0f0f0 !important;
}



.MuiSvgIcon-root {
  height: 18px ;
}


/* .MuiSvgIcon-fontSizeMedium {
  height: 25px !important;
} */





.buttonStyle {
  height: 45px !important;
  width: 110px !important;
  background-color: #1977D2 !important;
  color: white !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;

}

.go-to-main-page {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}




.gobackIcon {
  margin-bottom: 10.9px !important;
  cursor: pointer !important;
}

.css-1hwtqgt {
  padding: 0 !important;
  min-height: 150px !important;
  border-radius: 5px;
}
.css-1hwtqgt>h2{
  border-radius: 5px 5px 0 0;
}
.MuiTypography-root {
  border-top: 10px !important;
}

.css-g6lsph {
  padding: 0 !important;
  border-radius: 5px;
}
.css-g6lsph>h2 {
  border-radius: 5px 5px 0 0;
}

.thumbnail-container {
  height: 124px;
  width: 221px;
  margin: 16px;
}




iframe {
  border: none !important
}

.MuiDataGrid-cell--withRenderer {
  display: flex !important;
  justify-content: center !important;
}

.embed_v {
  padding: 1em 0 1em 1em;
  width: 75%;
  align-self: flex-end;
}

.css-2b8pcz {
  padding: 0 !important;
  border-radius: 5px;
}
.css-2b8pcz>h2 {
  border-radius: 5px 5px 0 0;
}



.MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
    justify-content: center;
}

.MuiDataGrid-cell--textLeft {
  display: flex !important ;
  justify-content: center !important ;
}


.icons_S {
  height: 20px !important ;
    color: #696969  !important;
    margin:0.1em !important
}

.MuiMenu-list ,.MuiList-root, .MuiList-padding  {
  list-style: none  !important ;
    margin: 0  !important ;
    padding: 0  !important ;
    position: relative  !important ;
    padding-top: 8px  !important ;
    padding-bottom: 8px  !important ;
    outline: 0  !important ;
    display: flex !important;
    flex-direction: column !important;
}
.icon_S_mobile {
  height: 13px !important ;
  color: #696969  !important;
  margin:0 !important
}

.loading-container {
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  gap:5px !important;
}
.css-1hw9j7s {
  gap:5px !important;

}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 50%;
}

@media screen and (max-width: 992px) {



  .date-pickers {
    width: 100% !important;
  }



  .edit_p_name  {
    line-height: 100% !important;
    white-space: nowrap !important ;
    font-size: x-large;
    height: 1px !important;
  }
  .edit_h {
    gap : 0 !important;
  }

  .editIcon {

  }  


  .arrowGoBack {

  }

  
  .css-2b8pcz  {
    position: absolute !important ;
    top: 50% !important ;
    left: 50% !important ;
    -webkit-transform: translate(-50%, -50%) !important ;
    -moz-transform: translate(-50%, -50%) !important ;
    -ms-transform: translate(-50%, -50%) !important ;
    transform: translate(-50%, -50%) !important ;
    width: 69% !important ;
    background-color: #fff !important ;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important ;
    padding: 0 !important ;
  }
  .rrs_channel,.rrs_export {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .line {
    border-bottom: 1px solid #e8e8e8;
    width: 91%;
    margin-left: 10px;
  }
  .details_section_title {
    display: flex !important ;
    justify-content: space-around !important ;
    gap: 38% !important ;
    padding:0.9em !important;

  } 
  .embed_title {
    font-weight: bolder;
    text-align: start;
    width: 99%;
  }
  .p_title {
    font-size: 16px;
  }
  .embed_v {
    padding: 0 !important;
    width: 75%;
    align-self: flex-end;
  }
  .css-1hwtqgt {
    width: 80% !important;
    height: 60% !important;
    border-radius: 5px;
  }
  .thumbnail-container {
    width: 120px;
    height: 68px;
  }
  .css-qg5mth-MuiTypography-root {
    height: 20% !important;
  }
  .css-nei0b5 {
    height: 20% !important;

  }

  .details-Modal {
    margin: 3px;
    font-size: 19px;
  }
  .modal_header {
    display: flex;
    justify-content: space-between;
    margin: 3px;
    font-size: 19px;
    gap:2px;
  }
  .integ_link_b {
    font-size: 12px;
  }
  .channel_b{
    font-size: 12px;

  }
  .embed_title {
    font-size: 12px;

  }
  .iframe_container {
    max-height: 100% ;
    max-width: 100% ;
  }
  .css-g6lsph {
    width: 80% !important ;
    height: 50% !important;
  }
  .css-g6lsph>#modal-modal-description{
    height: 80%;
  }
  .edit_h {
    display: flex !important ;
    align-items: center !important ;
    gap: 0.2em !important ;
  }
  .css-sghohy-MuiButtonBase-root-MuiButton-root {
    min-width: 106px !important;
  }
  .css-1hw9j7s {
    min-width: 106px !important;
  }

  .css-if1fzi-MuiButtonBase-root-MuiButton-root:hover {
    width: 38% !important;
  }
}
@media screen and (max-width:567px) {
  .css-g6lsph {
    width: 80% !important;
    height: 35% !important;
  }
}


.details_section  {
  display: flex !important;
  gap:26% !important;
}


.css-ix1f03-MuiTypography-root {
  margin-top:0 !important
}

.css-zx0575 {
  margin-top:0 !important
}

.edit_h {
  display: flex;
  align-items: center;
  gap: 10px;
}



.addIc {
  height: 23px !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color:#000000 !important;
  font-size: 16.5px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root,.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color:#000000 !important;
  font-size: 16.5px !important;


}
.tglbtn{
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: 0;
  font-size: 15px;
  transition: .4s;
}

.edit_p_name {
  height: 11px ;
} 




.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  border: 1px solid !important;


}




.insight {
  display: flex;
  align-items: center;
  gap:5px;
}

.css-17jjc08-MuiDataGrid-footerContainer  {
  width: 100%;
}






.uppy-DragDrop-note {
  white-space:pre-line !important;
 
}
.uppy-DragDrop-inner svg {
  display:none;
}


.MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel {
  display: inline-block !important ;
}


.MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
  display: inline-block;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  margin-top: 2px !important;
}

 .css-pdct74-MuiTablePagination-selectLabel {
  display: inline-block !important;
 }

 
.MuiTablePagination-selectLabel .css-pdct74-MuiTablePagination-selectLabel {
  display: inline-block !important ;
}
.MuiTablePagination-spacer .css-1psng7p-MuiTablePagination-spacer {
  display: inline-block !important ;
}
.uppy-DragDrop-inner::before{
  content: url('/public/upload.svg');
   background-size: 100% 100%;
    display: inline-block;
  
    /*size of your image*/
    height: 70px;
    width:55px;
    filter: opacity(0.25) drop-shadow(0 0 0 rgb(210, 210, 219));
    /*if you want to change the position you can use margins or:*/
    position:relative;
    top:5px;

  }




  .uppy-DragDrop-label{
    color: white !important ;
   text-align: center;
   font-size: 12px;
   position: relative;
   margin-left: auto;
   margin-right: auto;
    width: 26%;
    padding:7px;
    background-color:#1890ff;
    border-radius: 3px;
    transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
}
.title_label {
  font-weight: bold !important;
  margin-bottom: 5px !important;
}
.title_label::after {
  content: " *";
  color:red;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select  {
  margin-bottom: -12px !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  padding-right: 23px;
  width: 19px;
  height: 23px !important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  margin-top: 5px;
}



.tglHeader {
  width:fit-content;
  margin-top:2;
  margin-bottom:2;
}

.tgl_container {
  width: 100%;
}
.uppy-DragDrop-label {
  margin-bottom: 50px !important ;
}


.input {
   position: absolute;
  top:220px;
  transform: translate(50%,50%);
  width:49.5%;
  display: flex !important;
  justify-content: center;
}


.css-ahj2mt-MuiTypography-root {
  color: rgba(0,0,0,0.87);
  font-size: 1.4rem !important;
}


.uppyC {
  padding: 1em !important;
}



@media screen and (max-width: 992px) { 
  .uppyC {
     position: relative; 
  }
  .uppy-DragDrop-label {
    white-space: nowrap !important;
    width: 62% !important;
  }

  .input {
    white-space: nowrap;
    width: 51% !important;
    display: flex !important;
    justify-content: center;
  } 
  .css-ahj2mt-MuiTypography-root {
    font-size: 1.4rem;
  }

}
.css-i44wyl {
  width: 100% !important;
}


.css-1hw9j7s {
  gap: 5px !important;
}

.CommonHeader-container  {
  padding: 1.3%;
}


.acceptedDimenssion{
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: rgb(210, 210, 210);
  display: block;
  width: 280px;
}
.passwordLabel{
  font-weight: normal !important;
}
.confirmBtn{
  display: 'flex' !important ;
   justify-content: 'flex-end'!important;
    margin-top: 20px !important; 
    margin-bottom: 1px !important; 
    float: right; 
}
.input-focus {
  border-color: #19b1ec !important;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5) !important; 
}
.rrs_export {
  line-break: anywhere;
}

.modal h2 {
  width: unset !important;
}

.modal-delete-podcast .MuiDialogTitle-root .css-1ty026z {
  overflow: hidden !important;
  margin-left: -8px !important;
}

.radio_btn_container_edit .css-1h7anqn {
  display: flex !important;
  flex-flow: row-reverse !important;
}

.select-list-bit .css-fvipm8{
  height: 58px !important;
  display: flex !important;
  align-items: baseline !important;
}

.home-select-podcast .MuiOutlinedInput-input .css-qiwgdb {
  display: flex !important;
  align-items: center !important;
}
.modal-inputs-h2 .css-cwpu7v {
  border-radius: unset !important;
}

.home-select-podcast .css-qiwgdb .css-qiwgdb .css-qiwgdb {
  display: flex !important;
  align-items: center !important;
}

.modal .MuiDialogContent-root .css-1ty026z {
  overflow: hidden !important;
}

.contenu_container_Edit {
  display: flex !important;
    flex-direction: column !important;
}

.modal-delete-podcast .MuiDialogContent-root .css-1ty026z {
  overflow: hidden !important;
}

.confirm-modal-delete .css-1ty026z {
  overflow: hidden !important;
}

.multi-select-podcast .css-13sljp9 {
  width: 100% !important;
}

.select_options {
  margin-top: 24px !important;
}

.modal-inputs-h2 h2 {
  color: #FFF !important;
}

.modal-confirm-title {
  height: 80px !important;
}

.select_options .MuiInputLabel-formControl {
  margin-top: -42px !important;
  left: -11px !important;
}

.home-select-podcast .css-fvipm8 {
  height: 40px !important;
}

.multi-select-podcast css-13sljp9 {
  width: 100% !important;
}

.new-fields-style-podcast {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 20px;
}

.upload_file_inputs label {
  color: #000 !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
}

.data-grid .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  border-top: unset !important;
}

/* .podcast-text-area .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  height: 10rem !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: calc(15% - 10px) !important;
} */


/* .podcast-text-area .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  width: 200% !important;
} */

.multi-select-podcast {
  flex-direction: column !important;
  align-items: flex-start !important;
  margin-top: 0;
}

.multi-select-podcast label {
  left: -13px !important;
  line-height: 26px !important;
  margin-top: -46px !important;
}

.multi-select-podcast .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -12px !important;
  position: relative !important;
  left: -12px !important;
  line-height: 20px !important;
  margin-bottom: 0px !important;
}

.select-multiple {
  margin-top: 1px !important;

}

.multi-select-podcast .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 21.5px !important;
  margin-top: -12px !important;
}



.select_options .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -12px !important;
  position: relative !important;
  left: -12px !important;
  line-height: 20px !important;
  margin-bottom: 6px !important;
}

.upload-img label span {
  padding: 8px !important;
  margin-top: 10px !important;
}

.upload-img {
  width: 270px;
  margin-left: 1.5rem !important;
}

.upload-img img {
  width: 234px !important;
  height: 148px !important;
}

.podcast-date-picker-edit {
  display: grid;
  grid-template-columns: repeat(2fr);
  column-gap: 10px;
}

.podcast-date-picker1 {
  grid-column: 1;
  grid-row: 2;
  width: 100% !important;
}

.podcast-date-picker2 {
  grid-column: 2;
  grid-row: 2;
  width: 100% !important;
}

.multi-select-podcast .css-1nrlq1o-MuiFormControl-root {
  width: 100% !important;
}

.header_container_div .header_title {
  white-space: break-spaces !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.modal-inputs-h2 .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  color: #FFF;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  line-height: 1.6 !important;
  letter-spacing: 0.0075em !important;
  font-family: system-ui !important;
}

.modal-inputs-h2 .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  border-radius: unset !important;
}

.modal-inputs-h2 .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  border-radius: unset !important;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important;
}

.modal .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important;
  overflow: hidden !important;
}

.modal .css-ypiqx9-MuiDialogContent-root {
  margin: unset !important;
}

.modal .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  white-space: unset !important;
  margin-bottom: 17px !important;
}

.modal .css-ypiqx9-MuiDialogContent-root{
  overflow: hidden !important;
}

.pod-header-container .css-j204z7-MuiFormControlLabel-root{
  margin: unset !important;
}
.details-podcast-main .details-podcast-section {
  gap: 10px;
  justify-content: space-between;
  padding-bottom: .5%;
}

.details-podcast-main .details-podcast-section .details-podcast-title {
  font-size: 16px;
  font-family: Inter, sans-serif !important;
  color: #000000;
  line-height: 15px;
  font-variant: tabular-nums;
  margin-top: -4px !important;
  font-family: system-ui !important;
}

.details-podcast-main .details-podcast-section .details-podcast-info {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-weight: 400;
  height: 47px;
  padding: 0.5em;
}

@media only screen and (min-width: 500){
  .new-fields-style-podcast {
    display: block !important;
  }
}

.add_podcast_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3px;
  margin: 17px;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif !important;
}

.modal-delete-podcast .css-ypiqx9-MuiDialogContent-root{
  overflow: hidden;
}

.modal-inputs-styling .css-cyxlny-MuiPaper-root-MuiDialog-paper {
  border-radius: unset !important;
}

.modal-inputs-styling .css-1oi6th-MuiTypography-root {
  height: 238px !important;
  overflow: hidden !important;
}

.modal-inputs-styling .css-14b29qc {
  padding: unset !important;
}

@media only screen and (max-width: 504px) {
  .modal-inputs-styling .css-1oi6th-MuiTypography-root {
    height: 169px !important;
    overflow: hidden !important;
  }
  .modal-inputs-styling .css-14b29qc {
    padding: unset !important;
  }
  .iframe_container{
    height: 33vh;
    margin-bottom: -39px!important
  }
  .modal-inputs-podcast.modal-input-bit #modal-modal-description{
    min-height: 33vh!important;
  }

}

@media only screen and (min-width: 505px) and (max-width: 666px) {
  .modal-inputs-styling .css-1oi6th-MuiTypography-root {
    height: 185px !important;
    overflow: hidden !important;
  }
  .modal-inputs-styling .css-14b29qc {
    padding: unset !important;
  }
  .iframe_container{
    margin-bottom: -39px!important;
  }

}

@media only screen and (min-width: 667px) and (max-width: 834px) {
  .modal-inputs-styling .css-1oi6th-MuiTypography-root {
    height: 205px !important;
    overflow: hidden !important;
  }
  .modal-inputs-styling .css-14b29qc {
    padding: unset !important;
  }
  .iframe_container{
    margin-bottom: -23px!important;
  }
}

@media only screen and (min-width: 834px) {
  .iframe_container{
    margin-bottom: -22px!important;
  }
}


.modal-inputs-styling .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  color: #FFF !important;
}

.modal-inputs-styling .css-ypiqx9-MuiDialogContent-root {
  padding: 0px 0 0px 0px !important;
  margin-top: unset !important;
  margin-bottom: -8px !important;
}

.modal-inputs-styling .css-hlj6pa-MuiDialogActions-root {
  padding: 0px !important;
}

.modal-inputs-styling .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  border-radius: unset !important;
  overflow: hidden !important;
}

.modal-inputs-styling .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  /* max-height: 359px !important;
  max-width: 906px !important;
  width: auto !important; */
}

.modal-inputs-styling .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  background-color: transparent !important;
}

.modal-inputs-styling .css-10ghrmp-MuiPaper-root-MuiAppBar-root {
  box-shadow: unset !important;
  background-color: transparent !important;
  color: #fff !important;
  position: absolute !important;
}

.modal-inputs-styling .css-10v1bof-MuiButtonBase-root-MuiIconButton-root {
  padding: unset !important;
}

.modal-inputs-styling .css-1ctq51k {
  box-shadow: unset !important;
  color: unset !important;
  background: transparent !important;
  position: absolute !important;
}

.modal-inputs-styling .css-cwpu7v {
  background-color: transparent !important;
  border-radius: unset !important;
}
header.MuiPaper-root .MuiToolbar-root.MuiToolbar-gutters div.MuiTypography-root, header.MuiPaper-root .MuiToolbar-root.MuiToolbar-gutters h2{
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}
.modal-inputs-styling .css-1ty026z {
  padding: unset !important;
  overflow: hidden !important;
}

.modal-inputs-styling .MuiIconButton-sizeMedium .css-suikjb {
  color: #FFF !important;
}

.modal-inputs-styling .css-vubbuv {
  color: #FFF !important;
}

.modal-inputs-styling .css-qfso29-MuiTypography-root-MuiDialogContentText-root {
  margin-top: unset !important;
}

.modal-inputs-styling .MuiDialogContent-root .css-1ty026z {
  overflow: hidden !important;
  margin-bottom: -4px;
}
.modal-inputs-styling .MuiDialog-paperFullWidth .css-cwpu7v {
  overflow: hidden !important;
}
.modal-inputs-podcast .MuiDialogActions-root{
  display: none;
}

.modal-inputs-podcast header{
  display: none!important;
}
.modal-inputs-podcast.modal-input-bit #modal-modal-description{
  height: auto!important;
}
.modal-inputs-podcast.modal-input-bit .iframe_container{
  aspect-ratio: auto !important;
}
.modal-inputs-podcast.modal-input-bit .MuiDialogContent-root{
  padding: 0 !important;
}


.podcast-text-area .MuiInputBase-root{
  height: 120px !important;
}
/* 
.MuiAutocomplete-popupIndicatorOpen{
  outline: 3px !important;
  border: #000 2px solid;
} */

.suppTextPodcats{
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  width: 220px;
}
.suppText{
  font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0px;
text-align: left;
width: 220px;
margin-top: 1rem;
}
.messageDelete{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.8rem;
}