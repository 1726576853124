.ant-drawer-header{
  background-color: #f5f5f5 !important;  
}
.ant-drawer-body{
overflow: hidden;
}
.square {
  overflow-x: hidden;
  position: relative;
   margin-left: -21px;
  padding:20px;
  width:1000px;
  border-bottom:1px solid #f0f0f0;
  background-color: #f5f5f5 !important;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}
.titledetail{
  position: relative;
  font-size: 16px;
  font-weight: 500;
}
.logo{
  width: 100%;

}
