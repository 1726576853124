.title {
  font-size: 36em;
}
.header{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ant-upload.ant-upload-drag{
width: 90%;
margin: 20px;
}
.ant-divider-horizontal{
  margin: 5px !important
}
.ant-modal-header {
 background-color: #1890ff !important;
}
.ant-modal-title, .ant-modal-close-x{
  color: #F5F5F5 !important;
}

.uppy-DragDrop-container {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important
}
.uppy-DragDrop--isDragDropSupported {
  border: 1px solid #f0f0f0 !important;
}
.cardUpload {
  float: left;
  clear: none;
}
.ant-card-head-title {
  font-weight: 700;
}
.column div {
  width: 30px;
  height: 20px;
  padding: 0;
}
.column div:first-child {
  margin-left: 25px;
}
.cardform:hover {
  color: hsla(0, 0%, 0%, 0.3);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}
// /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 574px) {
  .cardform {
    width: 100%;
    padding: 0%;
    height: 56.25%;
    margin-bottom: 0.9em;
    margin-left: 0%;
    overflow: hidden;
    cursor: pointer;
    transition: all ease 200ms;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 90%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }
  .cardparent {
    width: 100%;
  }
  .title {
    font-size: 10px;
  }
}
@media only screen and (min-width: 575px) {
  .cardform {
    width: 100%;
    padding: 0%;
    height: 56.25%;
    margin-bottom: 0.9em;
    margin-left: 0%;
    overflow: hidden;
    cursor: pointer;
    transition: all ease 200ms;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 90%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }
  .cardparent {
    width: 100%;
  }
  .title {
    font-size: 10px;
  }
}
.video_group{
  .rc-virtual-list-holder{
    overflow-y: auto !important;
    overflow-anchor: auto !important;
    } 
   .rc-virtual-list-scrollbar{
    visibility: hidden !important;
   } 
    
}
// /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 90%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }
  .cardparent {
    width: 100%;
  }

  .title {
    font-size: 10px;
  }
}
// /* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10;
    right: 0;
    height: 95%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }
  .cardparent {
    width: 100%;
  }
  .title {
    font-size: 10px;
  }
}
// /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10;
    right: 0;
    height: 95%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }
  .cardparent {
    width: 100%;
  }
  .title {
    font-size: 10px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10;
    right: 0;
    height: 95%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }
  .cardparent {
    width: 100%;
  }
  .title {
    font-size: 10px;
  }
}
@media only screen and (min-width: 1500px) {
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    height: 93%;
    width: 85%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }
  .cardparent {
    width: 100%;
  }
  .title {
    font-size: 17px;
  }
}
.container:hover .overlay {
  opacity: 0.5;
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
@media only screen and (max-width: 400px) {
  .cardform {
    width: 50%;
    padding: 0%;
    height: 107px;
    margin-bottom: 0.9em;
    overflow: hidden;
    cursor: pointer;
    transition: all ease 200ms;
  }
  .cardparent {
    width: 100%;
  }
  .title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 300px) {
  .cardform {
    width: 50%;
    padding: 0%;
    height: 157px;
    margin-bottom: 0.9em;
    overflow: hidden;
    cursor: pointer;
    transition: all ease 200ms;
  }

  .cardparent {
    width: 100%;
  }
  .title {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1500px) {
  .cardparent {
    width: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1800px) {
  .cardparent {
    width: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #050505;
    cursor: pointer;
  }

  div .col {
    height: 500px;
  }
}


@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .cardparent {
    width: 180%;
  }

}
.additional-info{
  gap: 10px 0;
}
.additional-info,.form{
  display: flex;
  flex-direction: column;
  .ant-row{
    gap: 5px 0;
    .ant-form-item,.ant-upload,.ant-card{
      margin: 5px 0 10px 0;
    }
    .ant-card-body{
      padding: 0;
      .ant-input{
        padding:4px !important;
      }
    }
    .ant-col-24{
      margin-bottom: 10px !important;
    }
  }
  .ant-row-space-between{
    row-gap: 5px !important;
  }
  .ant-upload.ant-upload-drag{
    width: 100%;
    margin: 0;
  }
}

.div_btn_add_cancel {
  display: flex;
  flex-direction: row !important;
}

.btnSubmit {
  .spn_add {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.add_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 15px;

  .profile_title {
    font-size: 20px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
  }

  .btnCancel {
    margin-right: 10px;
  }
}
.add_div.add_div_edit .ant-col{
  display: flex;
  align-items: center;
  gap:0 20px;
  @media screen and (max-width:667px) {
  gap:0;
  }
}
.add_div.add_div_edit .ant-col a{
  color: black;
  text-decoration: none;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.add_div.add_div_edit .ant-col a:hover{
  background-color:rgb(230, 230, 230);
  box-shadow: 0 0 5px 2px rgb(230, 230, 230);
}
.add_div.add_div_edit .ant-col a p{
  margin-bottom: 0;
  @media (max-width: 576px){
    display:none
  }
}
.edit-buttons{
  width: 100px;
  @media (min-width: 576px){
    min-width: 100px;
  }
}
.edit-buttons.active{
  border: none !important;
  border-bottom:3px solid #1890ff !important;
}

.btnCancel {
  .spn_cancel {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}



.export-tab {
  padding: 2% 20%;
}

.card-hover {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: #2891DA;
    border: 1px solid #2891DA;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.06);

    .contenu-card {
      color: white;
    }
  }
}

.col-card {
  padding: 5% 0 5% 0;
  background-color: rgba(0, 0, 0, 0.04);
}

.col-card-export {
  width: 40% !important;
}

.div_global_imporLien {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title_import {
  width: 100%;
  height: 30%;
  border-bottom: 1px solid RGB(217, 217, 217);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.title_import span {
  width: 45%;
  font-size: 14px;
  font-weight: 400;
  color: RGB(24, 144, 255);
  cursor: pointer;
}

.title_import h4 {
  font-size: 16px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
}

.input_button_div {
  width: 60%;
  padding: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.row_url_div {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 2% 5%;
  border-radius: 40px;
  flex-shrink: 0;
  border: 1px dashed #ABABAB;
}
.input-url {
  width: 100%;
  height: 55px;
  flex-shrink: 0;
  text-align: center;
  border-radius: 10px;
}
.button-url, .button-url:focus, .button-url:active, .button-url:hover {
  width: 176px;
  height: 53px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #2F323F;
  border: 1px solid #2F323F;
  color: white;
  outline: none; 
  box-shadow: none;
  font-size: 23px;
  font-family: 'Helvetica Neue, Helvetica, Arial, sans-serif' !important


}

.input_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5%;
}

.icon_import {
  font-size: 30px;
  font-weight: 900;
  color: RGBA(0, 0, 0, 0.45);
  margin-right: 2%;
}

.input_div input {
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.25);
}

.button_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button_div Button {
  margin-right: 3%;
}

.input_div_dirve {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  text-align: center;
}

.input_div_dirve p {
  text-align: center;
  margin-top: 2%;
  font-size: 16px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.85);
}

.col-key-form {
  padding: 1%;
  border: 1px solid RGBA(0, 0, 0, 0.15);
  border-radius: 2px;
}

.keyword-col {
  border: 1px solid;
}

.file-linked {
  border: 1px solid RGBA(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px;
}

.export_google_drive {
  width: 100%;
}

.header_export_google_drive {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid RGB(217, 217, 217);
  border-top: 1px solid RGB(217, 217, 217);
  padding: 1.2% 0.5%;
}

.title_header_export_google_drive {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.googleIcon {
  font-size: 17.5px;
  color: RGBA(0, 0, 0, 0.45);
  margin-right: 1%;
}

.title_header_export_google_drive span {
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.85);
}

.email_deconnect_export_google_drive {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.span1_email_deconnect_export_google_drive {
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.85);
  margin-right: 6%;
}

.span2_email_deconnect_export_google_drive {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.45);
}

.icon_logout {
  font-size: 17.5px;
  margin-left: 5px;
}

.div_selection_vignette {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2%;
  padding: 0 1%;
  overflow-y: scroll;
}

.div_selection_vignette::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.div_selection_vignette::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.div_selection_vignette::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: gray;
}

.chbx1 {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: RGBA(0, 0, 0, 0.85);
}

.div_chbx {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3%;
  padding: 0.7% 0;
  border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
}

.div_chbx img {
  width: 93px;
  height: 54px;
  margin-left: 0.5%;
}

.div_chbx p {
  margin-left: 2%;
  width: 100%;
}

.div_btn_import {
  width: 100%;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.div_btn_import Button {
  margin-right: 1%;
}

@media (max-width: 768px) {
  .col-card-export {
    width: 30% !important;
  }

  .card-hover .contenu-card {
    font-size: 20px;
    height: 30px;
  }
}

@media (max-width: 576px) {
  .col-card-export {
    width: 50% !important;
  }
}
.confidentialité-checkbox.ant-row, .confidentialité-checkbox .ant-row{
  width: 100%;
  margin-top: 10px;
}
.confidentialité-checkbox .ant-form-item{
  margin-bottom: 10px;
  width: 100%;
}
.confidentialité-checkbox .ant-form-item-control-input-content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 20px 10px;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
}
// ---- modal edit video ----
.modal-edit-video{
  width:60% !important;
  .ant-modal-body{
    padding: 12px 0 5px 18px ;
  }
  @media (max-width: 576px) {
    width: 100% !important;
    div.ant-col span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 90%;
    }
    .modal-edit-icons{
      display: none !important;
    }
  }
}
// !------------- vignette design -----------------
.vignette{
  width:100%;
  height:56.25%;
}
// !------------ upload video page ----------------
.upload-page{
  display: flex;
  justify-content: center;
  .ant-tabs{
    width: 98%;
  }
  .ant-tabs-nav{
    margin-left: 35px;
  }
}
.iframe-video-pending, .iframe-video-pending .ant-modal-body{
  width:1138px !important;
  height:640px !important;
  background-color: transparent !important;
}
@media screen and (max-width:1200px) {
  .iframe-video-pending, .iframe-video-pending .ant-modal-body{
    width:798px !important;
    height:449px !important;
  }
}
@media screen and (max-width:992px) {
  .iframe-video-pending, .iframe-video-pending .ant-modal-body{
    width:498px !important;
    height:280px !important;
  }
}
@media screen and (max-width:576px) {
  .iframe-video-pending, .iframe-video-pending .ant-modal-body{
    width:96vw !important;
    height:54vw !important;
  }
}