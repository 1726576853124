.DaashBoardContainer{
    height: 100vh;
}

.DaashBoardContainer .liniaire{
    height: 17vh;
    background: linear-gradient( 
  to bottom, 
  #cde4fa 40%, 
  #e8f4ff 73%, 
  #f3f9ff 81%, 
  #FFFFFF 100% 
);
display: flex;
align-items: center;
gap: 1rem;
padding: 2rem 6rem;
margin-bottom: 2px !important;

}

.DaashBoardContainer .titleHeader{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 60px !important;
}

.DaashBoardContainer .biggerTitle{
    font-size: x-large;
    font-weight: 700;
    margin-bottom: 0em;
}

.DaashBoardContainer .imageTitle{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: contain;
    text-align: center
}
.DaashBoardContainer .stat{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 10px;
    padding: 1rem;
    height: 100px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 15px;
    width: 100%;
}
.DaashBoardContainer .stat-desc{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.DaashBoardContainer .stat-title{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    color: #FA541C;
    font-weight: 500;
    margin-bottom: 0;
    white-space: nowrap;
}

@media only screen and (max-width: 1360px) {
    .DaashBoardContainer .stat-title{
        white-space: pre-line;  
    }
}

.DaashBoardContainer .stat-num{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #1B2559;
    font-size: 20px;
}

.DaashBoardContainer .orange{
    background-color: #FFFAF5;
}
.DaashBoardContainer .blue{
    background-color: #F4F7FE;
}
.DaashBoardContainer .rose{
    background-color: #FFF5F9;
}
.DaashBoardContainer .white{
    background-color: #FFFFFF;
}
.DaashBoardContainer .darkorange{
    color: #FA541C;
}
.DaashBoardContainer .darkblue{
    color: #1890FF;
}
.DaashBoardContainer .darkrose{
    color: #FF237B;
}
.DaashBoardContainer .darkwhite{
    color: #8cb1fa;
}


.DaashBoardContainer .headerDemo{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    border: 1px rgb(206, 206, 206) solid ;
    padding: 10px 16px;
    height: 60px;
}
.DaashBoardContainer .bodyDemo{
    border: 1px rgb(206, 206, 206) solid ;
    border-top: none;
    padding: 0px 16px;
    height: calc(98% - 60px);
    overflow: scroll;
    
}
.DaashBoardContainer .fullHeight{
    max-height: 400px;
    height: 130%
}
.DaashBoardContainer .oneGrid{
    height: 100%;
    position: relative;
    max-height: 260px;
}
.DaashBoardContainer .headerDemo span {
    font-size: 20px;
}
.DaashBoardContainer .displayPlayList{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #bbb;
    padding: 2px 0px;
    height: 60px;
    width: 100%;
}
.DaashBoardContainer .getOffColomun{
    margin-left: auto;
}
.DaashBoardContainer .display{
    display: flex;
    align-items: center;
    position: relative;
    gap: 0.6rem;
    width: 100%;
    border-bottom: 1px solid #bbb;
    padding: 2px 0px;
}
.DaashBoardContainer .image{
    width: 110px;
    height: 60px;
}

.DaashBoardContainer .selectinnedVideoData{
    display: flex;
    flex-direction: column;    
}
.DaashBoardContainer .timeDisplay{
    display: flex;
    justify-items: center;
    align-items: center;
    color: grey;
}
.DaashBoardContainer .grid2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem !important;
    row-gap: 2rem !important;
  }

  .DaashBoardContainer .playListDis{
    justify-content: space-between;
  }
  .DaashBoardContainer .checkBoxWithTitle{
    display: flex;
    gap: 2rem;
  }
  .DaashBoardContainer table {
    width: 100%;
    border-collapse: collapse;
  }
  .DaashBoardContainer .bigger{
    font-size: medium;
    font-weight: 500;
  }
  .DaashBoardContainer .biggerNumber{
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .DaashBoardContainer td{
    width: 25%;
  }
  .DaashBoardContainer .actionModification{
    float: right;
  }
  .DaashBoardContainer .center{
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .DaashBoardContainer #livesLong{
    width: 250px !important;
  }
  .DaashBoardContainer .icon_action:hover{
    cursor: pointer; 
  }

@media only screen and (max-width: 550px) {
    .DaashBoardContainer #livesLong{
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
        width: 200px !important;
    }
    .DaashBoardContainer .liniaire{
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: .5rem 0rem;
        margin-bottom: 25px !important;
        
        
    }
    .DaashBoardContainer .titleHeader{
        align-items: center;
        justify-content: center;
        text-align: center !important

    }
    
}

@media only screen and (min-width: 767px ) and (max-width: 1150px) {

    .DaashBoardContainer .grid{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0.5em;
        margin-bottom: 1rem;
        margin-inline: auto;
    }
    .DaashBoardContainer .oneGrid{
        max-height:none;
    }
}
@media only screen and (min-width: 1150px ) {
    .DaashBoardContainer .grid{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0.5em;
        margin-bottom: 1rem;
        margin-inline: 5rem;

    }
    .DaashBoardContainer .grid2{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin-inline: 5rem;
        gap: 8rem;
        row-gap: 2rem;
        margin-top: 4rem;
    }
     .DaashBoardContainer .grid3{
        display: grid;
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 2fr;
        gap: 1rem !important;
        row-gap: 2rem !important;  
        margin-inline: 5rem;
        height: 570px;
    }
    .DaashBoardContainer .first {
        grid-row: 1 / 2; 
        grid-column: 1 / 2; 
      }
      
      .DaashBoardContainer .second {
        grid-row: 1 / 3; 
        grid-column: 2 / 3;
      }
      
      .DaashBoardContainer .third {
        grid-row: 2 / 3; 
        grid-column: 2 / 3; 
      }
.DaashBoardContainer .grid4{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr ;
    gap: 1rem !important;
    row-gap: 2rem !important;  
    margin-inline: 5rem;
    height: 600px;
}
    
    
}
.DaashBoardContainer .actionModification{
    display: flex !important;
    flex-direction: row !important;
}
@media only screen and (max-width: 767px ) {
    .DaashBoardContainer .grid{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .0DaashBoardContainer.5em;
        margin-bottom: 1rem;
        margin-inline: auto;

    }
    .DaashBoardContainer .oneGrid{
        max-height:1000px;
    }
}
@media only screen and (max-width: 500px ) {
     .headerDemo > span {
        font-size: 14px !important;
    }
     .bigger {
        line-height: normal;
    }
}
@media only screen and (max-width: 900px ) {
    .DaashBoardContainer .grid2{
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin-top: 2rem;
    }
    .DaashBoardContainer .bodyDemo{
        max-height: 100%;
    }
    .DaashBoardContainer .oneGrid{
        max-height:1000px;
    }
}