.divGlobalTablePlayListe .detailsUserDiv_spn1{
  text-align: right;
}

.divGlobalTablePlayListe .divCard {
margin-left: 2% !important;
width: 96% !important;
display: flex;
flex-direction: column;
position: relative;
gap: 1rem;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  

  .modal-playlist-details h2 {
    color: #FFF;
    font-size: 1.25rem !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    font-family: system-ui !important;
  }
  
  .playlist-details-modal{
    width: 100%;
    display: flex;
    flex-direction: column;
    .playlist-details-modal-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      padding-bottom: 1%;
      .playlist-details-modal-title{
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      }
      .playlist-details-modal-value{
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        height: auto;
        padding: 11px;
        border: 1px solid #e0e0e0;
        color: RGBA(0, 0, 0, 0.85);
      }
    }
  }
.divGlobalTablePlayListe{
  height: 97vh !important;
  padding: 10px 10px 0px 10px;
  margin-bottom: 0 !important;
  position: relative;
}
.divGlobalTablePlayListe .formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .divGlobalTablePlayListe .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .divGlobalTablePlayListe .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .divGlobalTablePlayListe .ant-col-22 {
    max-width: 100% !important;
  }

  .divGlobalTablePlayListe .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5% !important;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
    }
  }

  .divGlobalTablePlayListe .Filter{
  display: flex;
  flex-direction: row !important;
  align-items: center;
  margin-top: 1rem;
  box-shadow: 0px 4px 10px 2px rgba(0 ,0 ,0 ,0.3);
}

@media only screen and (max-width: 767px) {
  .divGlobalTablePlayListe .Filter{
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
  }
  
}
.divGlobalTablePlayListe .ant-form-item-label {
    text-align: left !important;
    width: 100% !important;
  }
  // .divGlobalTablePlayListe .css-bhp9pd-MuiPaper-root-MuiCard-root{
  //   box-shadow: none !important;
  // }
  .divGlobalTablePlayListe .AdresseIP{
width: 80% !important;
  }
  .divGlobalTablePlayListe .LabelCard{
color : black !important; 
font-weight: bold !important;
  }
  .divGlobalTablePlayListe .textFieldModal{
    margin-bottom: 3% !important;
    width : 98% !important ;
    margin-right : 2% !important
  }
  .divGlobalTablePlayListe .closeButton{
    color: #1976D2 !important;
  border: 1px solid 	#1976D2 !important;
  margin-left : 1% !important;
  float : right !important;
  margin-right : 2% !important; 
  margin-top: 3% !important; 
  padding-left: 2% !important;
  padding-right: 2% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  border-radius: 5% !important;

  }
  .divGlobalTablePlayListe .title{
  font-weight: bold !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2% !important;
  
}
.divGlobalTablePlayListe .highlightIcon{
  color : black !important
}
.divGlobalTablePlayListe  .dataTable {
  height: 100% !important;
  width: 100% !important;  
  margin-top : 1% !important; 
  border: none !important; 
  margin-bottom: -20px !important;

}
.noDataGridStylingCenter {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%) !important;
}

.MuiTooltip-popper[data-popper-placement*="top"] .css-1spb1s5{
  margin-bottom: 0 !important;
}

.divGlobalTablePlayListe .icon_action{
  font-size:1.3rem !important
}
// .divGlobalTablePlayListe .css-qg0cwk-MuiPaper-root {
//   box-shadow: none !important;
// }
.divGlobalTablePlayListe .search{
 padding: 2px 4px !important;
  display: flex !important;
   align-items: center !important;
    width: 100% !important;
      box-shadow: none !important;
      margin-left: 2% !important;
      width: 95% !important;
      margin-right: 5% !important;
        background-color:#F0F0F0 !important;
        opacity: 30% !important;
       
}
.divGlobalTablePlayListe .addbutton{
  background-color: #1890FF !important ;
  color: white !important ;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  margin-left: 45% !important;
}
.divGlobalTablePlayListe .btnCancel{
  background-color: white !important ;
  color: #1976D2 !important ;
  border : 2px solid #1976D2 !important ;
  padding-left: 10% !important ;
  padding-right: 10% !important ;


}

.divGlobalTablePlayListe .btnSubmit{
  background-color: #1890FF !important;
  color: white !important;
  padding-left: 10% !important ;
  padding-right: 10% !important ;
}
.divGlobalTablePlayListe .div_btn_add_cancel{
  width: 280px !important;
}
.divGlobalTablePlayListe .Breadcrumb{
  margin-left: 2% !important;
}
.divGlobalTablePlayListe .search-bar-Ip-main{
  margin-top: 0;
  border-radius: 6px;
}

.loading-container {
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.divGlobalTablePlayListe .theme{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.notFoundContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divGlobalTablePlayListe .action{
  display: flex !important;
  flex-direction: row !important;
  width: 30% ;
  align-items: center !important;
  justify-content: space-between !important;
  div{
    .icon_action{
      cursor: pointer !important;
      font-size: 1.3rem !important;

    }
  }
  // @media screen and (max-width: 768px) {
  //   display: flex !important;
  //   flex-direction: column !important;
  //   justify-content: space-between !important;
  // }
}

.divGlobalTablePlayListe > .modal-confirm-title {
  height: 52px !important;
}
.modal-confirm-title {
  height: 52px !important;
}
.divGlobalTablePlayListe .tableDatta{
  height: 100%;
}
 
.divGlobalTablePlayListe .Filter .delete_number{
  position:relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  margin-inline: 1rem !important;
}

.divGlobalTablePlayListe .pagination-footer select  {
  width: auto !important;
}
// .divGlobalTablePlayListe .allllll .DataTable  
.playlist-details-modal .playlist-details-modal-container .playlist-details-modal-value .vert{
  color: green;
}
.playlist-details-modal .playlist-details-modal-container .playlist-details-modal-value .rouge{
  color: red;
}
.divGlobalTablePlayListe .vert{
  color: green;
}
.divGlobalTablePlayListe .rouge{
  color: red;
}
.divGlobalTablePlayListe .action {
  width: 150px !important;
  height: 35px !important;
}

 .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
background-color: rgba(0, 0, 0, 0.1) !important;
}
.css-tlc64q-MuiPaper-root-MuiDialog-paper{
box-shadow:none !important;
}

// .divGlobalTablePlayListe .css-qfso29-MuiTypography-root-MuiDialogContentText-root{
// margin-top: 15px !important;
// }
.divGlobalTablePlayListe .addEditGlobal{
position: relative;
width: 98%;
display: flex;
flex-direction: row;
justify-content: center;
gap: 1rem;
margin-inline: 15px;

}
.divGlobalTablePlayListe .globalcol{
flex-direction: column-reverse !important;
}
.divGlobalTablePlayListe .part1{
width: 55%;
padding: 10px;
max-height: 700px;

}
.divGlobalTablePlayListe .part1Expand{
width: 100% !important;
}
.divGlobalTablePlayListe .part2{
width: 45%;
padding: 10px;
display: flex;
flex-direction: column;
gap:1.5rem


}
.divGlobalTablePlayListe .part2Expand{
width: 100% !important;
}
.divGlobalTablePlayListe .header_container_div{
margin: 0 0 12px 0 !important;
}

.divGlobalTablePlayListe .Header{
font-weight: 700;
font-size: 18px;
line-height: 22px;
margin: 13px 0 13px 0;
min-width: 200px;
}

.divGlobalTablePlayListe .bob{
width: 100% !important;
}
.divGlobalTablePlayListe .grid2{
position: relative;
display: grid;
grid-template-columns: 1fr 1fr;
grid-row: auto;
gap: 0.8rem;
margin-bottom: 0.8rem;
}

.divGlobalTablePlayListe .grid{
position: relative;
display: grid;
grid-template-columns: 1fr 1fr;
grid-row: auto;
gap: 0.8rem;
margin-bottom: 0.8rem;
max-height: 670px;
overflow: scroll;
scroll-behavior: auto;
overflow-anchor: none;
touch-action: none;
scroll-snap-type: none;
-webkit-overflow-scrolling: touch;
scroll-padding: 0 0 50px;
padding: 10px 0px;
}


.divGlobalTablePlayListe .colsVids{
grid-template-columns: 1fr !important;
grid-row: auto !important;
}
.divGlobalTablePlayListe .InputCols{
grid-template-columns: 1fr !important;
grid-row: auto !important;
}
// .divGlobalTablePlayListe .css-1knaqv7-MuiButtonBase-root-MuiButton-root{
// margin-left: auto !important;
// color: #1890FF;
// }


.divGlobalTablePlayListe .align{
display: flex !important;
align-items: center !important;
}
.divGlobalTablePlayListe .align,.divGlobalTablePlayListe span{
margin-top: 3px;
text-transform:none;
}
.divGlobalTablePlayListe .alignsAddToPlaylist{
  margin-left: auto;
}
// .divGlobalTablePlayListe .css-ahj2mt-MuiTypography-root{
// font-family: Roboto !important;
// margin-top: 4px !important;
// font-weight: 400 !important;
// font-size: 14px !important;
// line-height: 22px !important;
// }
// .divGlobalTablePlayListe .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
// height: 2.1em !important;
// width: 350px !important;
// }

.divGlobalTablePlayListe .name{
font-family: Inter, Roboto, Helvetica, Arial, sans-serif;
font-weight: bold;
font-size: 16px;
line-height: 0px;
color: rgba(0, 0, 0, 0.87);
letter-spacing: 0.01071em;
}

// .divGlobalTablePlayListe .css-j204z7-MuiFormControlLabel-root{
// margin: 30px 0 !important;
// }
.divGlobalTablePlayListe .vidContainer span{
  color: #9C9C9C;
}
.divGlobalTablePlayListe .vidContainer{
border: rgb(194, 194, 194) 1px solid;
padding: 40px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
.divGlobalTablePlayListe .container{
margin-top: 4px;
height: 32vh;
overflow: scroll;
}

.divGlobalTablePlayListe .image{
width: 110px;
height: 60px;
}
.divGlobalTablePlayListe .selectinnedVideoData{
display: flex;
flex-direction: column;

}
.divGlobalTablePlayListe .display{
display: flex;
align-items: center;
position: relative;
gap: 0.6rem;
width: 100%;
border-bottom: 1px solid #bbb;

}

.divGlobalTablePlayListe .timeDisplay{
display: flex;
justify-items: center;
align-items: center;
color: grey;
}

.divGlobalTablePlayListe .rightt{
position: absolute;
margin: auto;
right: 5px;
cursor: pointer;
}
.divGlobalTablePlayListe .movement{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.divGlobalTablePlayListe .up:hover,.divGlobalTablePlayListe .down:hover{
cursor: pointer;
}

.divGlobalTablePlayListe .disable{
color: grey;
cursor: not-allowed !important;
}
.divGlobalTablePlayListe .all{
position: absolute;
top: 0 !important;
bottom: 0 !important;
right: 0 !important;
left: 0 !important;

height: 100vh;
width: 100vw;
background-color: rgba(0, 0, 0, 0.6) !important;
z-index: 99999999;
}


.divGlobalTablePlayListe .video-container {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
background-color: #000000;
}

.divGlobalTablePlayListe .video-container iframe {

width: 65vw;
height: auto;
aspect-ratio: 16/9;
}

.divGlobalTablePlayListe .text {
position: relative;
opacity: 0;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
color: #fff;
background-color: rgba(0,0,0,.5);
transition: opacity .3s ease-in-out;
letter-spacing: 1px;
margin: 0;
font-weight: 500;
}
.divGlobalTablePlayListe .close-btn {
position: absolute;
top: -0.5%;
right: 7px;
color: white;
font-weight: bold;
background: none;
border: none;
font-size: 30px;
cursor: pointer;
text-shadow: -1px 0px 0px black, 1px 0px 0px black, 0px -1px 0px black, 0px 1px 0px black;
}

.divGlobalTablePlayListe .headsearch{
display: flex;
align-items: center;
flex-direction: column !important;
gap: 1rem;

}
// .divGlobalTablePlayListe .css-xsqzfj-MuiPaper-root{
//   opacity: 75%;
//   height: 45px;
//   margin-top: 2px;
//   box-shadow: 0px 4px 10px 2px rgba(0 ,0 ,0 ,0.3);
// }
@media screen and (min-width: 767px) {
  .divGlobalTablePlayListe .headsearch{
    display: flex;
    align-items: center;
    flex-direction: row !important;
    align-items: start;
    gap: 1rem;
  }
  
}
.divGlobalTablePlayListe .headsearch .textThemeAddEdit label{
  color: rgba(0, 0, 0, 0.2);
  transform: translate(14px, 8px);
}
.divGlobalTablePlayListe .headsearch .Mui-focused .textThemeAddEdit label{
  transform: translate(14px, -9px) scale(0.75);
}
.divGlobalTablePlayListe .headsearch .MuiBox-root{
  margin: 0;
  padding-top: 0;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: 1px solid #E0E0E0 !important;
}
.css-1s4dedr-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 3.2px !important;
}
.divGlobalTablePlayListe .notFoundtVideos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.divGlobalTablePlayListe .header_title {
  display: flex !important;
  gap: 0.5em;
  white-space: nowrap;
align-items: center;
font-size: 18px ;
font-weight: 500 ;
color: RGBA(0, 0, 0, 0.85);
font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

// .divGlobalTablePlayListe .css-1x5jdmq {
  // height: 2.1em !important;
  // }
  // .divGlobalTablePlayListe .css-1rtnrqa {
    // cursor: pointer;
    // font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    // font-weight: 700 !important;
    // margin-left: auto !important;
    // }
    
    // .divGlobalTablePlayListe .css-24nx7i {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      // }

      // .divGlobalTablePlayListe .css-o3d33y{
        // margin-top: 15px !important;
        // }
        
        // .divGlobalTablePlayListe .css-919eu4 {
          // background-color: rgba(0, 0, 0, 0.1) !important;
          // }
          
          // .divGlobalTablePlayListe .css-mbdu2s {
            // box-shadow: none !important;
// }

// .divGlobalTablePlayListe .css-cc8tf1{
  // font-weight: 700 !important;
  // }
  
  
  .modal-playlist-details > .css-919eu4 {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  
  .modal-playlist-details > .css-ekeie0 > .css-mbdu2s {
    box-shadow: none !important;
  }
  .divGlobalTablePlayListe .headsearch  .css-xsqzfj-MuiPaper-root{
    box-shadow: none !important;
    border: 1px solid #E0E0E0;
    border-radius: 6px ;
  }

  .colomunHeaderUnique{
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bolder !important;
    letter-spacing: 0.01071em !important;
    font-size: 14px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .toghetherAllArrows{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: 0.4rem;
  }

  .divGlobalTablePlayListe .headsearch .MuiOutlinedInput-root{
    padding: 1.5px !important;
  }

  .divGlobalTablePlayListe .addEditGlobal .part2 .grid2 .MuiBox-root .MuiInputBase-input {
    padding: 11.5px 14px !important;
  }
  .divGlobalTablePlayListe .addEditGlobal .part2 .MuiInputBase-input {
    padding: 11.5px 14px !important;
  }

  .custom-select .MuiSelect-select{
    color: rgba(0, 0, 0, 0.2);
  }

  .headerAlign{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
    #list_video::after {
      content: "*";
      color: rgb(199, 18, 18);
    }