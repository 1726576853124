.checked-item,
.not-checked-item {
  color: brown;
}
.ant-checkbox-wrapper-checked.checked-item{
background-color: brown !important;
display: none;
visibility: hidden;
}
.ant-list-item.ant-list-item-no-flex.checked-item{
  background-color: brown !important;
display: none;
visibility: hidden;
}
.svg_icons {
  height: 15px !important;
  transform: scale(1.8);
  cursor: pointer;
  color: rgba(0,0,0,0.54);
}

.scroll-container {
	max-height: 35rem;
	overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .remove_user_class {
    position:relative;
    margin-left: 10px;
  }
  .add_user{
    position:relative;
    margin-left: 80px; 
  }
}
@media only screen and (min-width: 768px) {
  .remove_user_class {
    position:relative;
    margin-top:75px;
    right:18px
  }
} 
